<template>
  <div>
    <Projectsidebarmenu :sidebar="sidebar" />
    <v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
    <v-main class="ml-1 pt-0 pa-15">
      <v-row>
        <v-col cols="12" md="5">
          <h1 class="mb-5">Tellen en Vertellen</h1>
          <p>
            Illo sed beatae maxime. In perferendis omnis. Error doloribus
            voluptas sed omnis vel aut laboriosam saepe est. Molestiae
            reprehenderit aut eius deleniti.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="text-right ml-auto my-auto">
          <v-btn :to='"/project/" + $route.params.id + "/tellen-en-vertellen/nieuw"' class="text-white btn"
            :disabled="!hasaccess" color="themepurple">
            <v-icon left>fas fa-plus-circle</v-icon> Tellen en Vertellen Toevoegen</v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-list  color="transparent">
            <v-list-item  :to="item.to"  two-line v-for="item in items" :key="item.id">
              <v-list-item-content >
                <v-list-item-title class="text-purple fs-20">{{
                  item.title
                }}</v-list-item-title>

                <v-list-item-subtitle>
                  Gestart: {{ new Date(item.datestarted * 1000).toLocaleDateString("nl-NL", {
                    timeZone:
                      "Europe/Amsterdam"
                  }) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="items.length == 0">
              <v-list-item-content>
                <v-list-item-subtitle>
                  Er zijn nog geen tellen en vertellen formulieren.

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-footer class="ml-2 pa-10" color="transparent" fixed>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn class="mr-3 btn" outlined to="/" color="themepurple">Terug</v-btn>
            <v-btn class="btn" disabled @click="next()" dark color="themepurple">Volgende</v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import Projectsidebarmenu from '@/components/projectsidebarmenu.vue';
import api from "../../../services/api";
import config from '../../../config';
import { mapGetters } from 'vuex'
export default {
  components: { Projectsidebarmenu },
  data() {
    return {
      sidebar: true,
      ready: true,
      nextdialog: false,
      startcountingdialog: false,
      senddata: false,
      hasaccess: true,
      items: [
      ],

      tab: 0,
    };
  },
  computed: {
    ...mapGetters({ user: 'getuser' }),
  },
  mounted() {
    this.getProjectCountingsAndTellings();
  },
  methods: {
    getProjectCountingsAndTellings() {
      api.get(`${config.url.api}/v1/countingandtelling/${this.$route.params.id}`).then((res) => {
        this.userrole = res.data.userrole;
        if (res.data.success) {
          this.hasaccess = true;
          res.data.data.forEach((cat, index) => {
            this.items.push({ title: "Tellen en vertellen " + (index + 1), datestarted: cat.created_at, to: `/project/${this.$route.params.id}/tellen-en-vertellen/${cat.slug}` })
          });
          this.hasaccess = res.data.userrole == "PU";
          console.log(this.hasaccess);

        } else {

          //no existing counting and tellings found.
          this.hasaccess = res.data.userrole == "PU";
        }
      })
    },
    next() {
      this.tab++;
      window.scrollTo(0, 0);
    },
    prev() {
      this.tab--;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style>
body {
  transition: all ease-in-out 0.2s;
  background-color: #e8f4f6;
}
</style>
