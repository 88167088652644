<template>
  <div>
    <v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%"
      color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0">
        <v-list flat light>
          <v-list-item-group v-model="tab" light>
            <v-list-item color="themepurple" v-for="(item, i) in menuitems" :key="i" :disabled="i == tab">
              <v-list-item-title :key="i">
                {{ item.step }} {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-spacer></v-spacer>
        </v-list>

      <p class="bottom-text">Tellen en Vertellen</p>
    </v-navigation-drawer>
    <v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
    <v-main class="pa-0 ml-1 pt-0 pa-15">
      <div v-if="tab === 0">
        <h1 class="mb-5">Projectkenmerken</h1>
        <v-row>
          <v-col cols="12" md="6">
            <label for="">Projectnaam</label>
            <v-text-field v-model="project.title" readonly background-color="#D7D7E1" class="theme-input" solo
              title="Projectnaam"></v-text-field>
            <label for="">Doelstelling</label>
            <v-text-field :readonly="!edit" v-model="data.project.purpose" background-color="#D7D7E1" class="theme-input"
              solo title="Doelstelling"></v-text-field>

            <label for="">Rapportageperiode</label>
            <v-text-field :readonly="!edit" v-model="data.project.period" background-color="#D7D7E1" class="theme-input"
              solo title="Rapportageperiode"></v-text-field>

            <label for="">Opdrachtgever</label>
            <v-text-field :readonly="!edit" v-model="data.project.client" background-color="#D7D7E1" class="theme-input"
              solo title="Opdrachtgever"></v-text-field>

            <label for="">Budgethouder</label>
            <v-text-field :readonly="!edit" v-model="data.project.budgetowner" background-color="#D7D7E1"
              class="theme-input" solo title="Budgethouder"></v-text-field>
          </v-col>
        </v-row>

        <v-footer class="ml-2 pa-10" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined  @click="submit('concept')" v-if="edit"
                color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined to="/" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 1">
        <v-row>
          <v-col cols="12" md="6">
            <h2 class="mb-5">Hoe staat het ervoor met je project?</h2>

            <Feedbacksmiley class="mb-10" :edit="edit" @update="data.feedback = $event" :val="data.feedback" />

            <label>Korte duiding over de score</label>
            <v-textarea :readonly="!edit" background-color="#D7D7E1" class="theme-input" solo v-model="data.description"
              title="Korte duiding over de score"></v-textarea>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined  @click="submit('concept')" v-if="edit"
                color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 2">
        <v-row>
          <v-col cols="12" md="6">
            <h2 class="mb-10">
              Welke stappen heb je gezet op weg naar je resultaten?
            </h2>
            <v-expansion-panels accordion flat>
              <accordion title=" <p> <b>Resultaat 1</b></p>" :style="`border-color: #AFAFC3!important;`">
                <v-text-field :readonly="!edit" v-model="data.results[0].result" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Welke stap(pen) heb je gezet op weg naar resultaat 1?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[0].step" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Periode</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[0].period" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Wat heb je daarmee bereikt?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[0].achievement" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
              </accordion>

              <accordion title=" <p> <b>Resultaat 2</b></p>" :style="`border-color: #AFAFC3!important;`">
                <v-text-field :readonly="!edit" v-model="data.results[1].result" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Welke stap(pen) heb je gezet op weg naar resultaat 2?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[1].step" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Periode</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[1].period" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Wat heb je daarmee bereikt?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[1].achievement" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
              </accordion>

              <accordion title=" <p> <b>Resultaat 3</b></p>" :style="`border-color: #AFAFC3!important;`">
                <v-text-field :readonly="!edit" v-model="data.results[2].result" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Welke stap(pen) heb je gezet op weg naar resultaat 3?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[2].step" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Periode</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[2].period" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Wat heb je daarmee bereikt?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[2].achievement" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
              </accordion>

              <accordion title=" <p> <b>Resultaat 4</b></p>" :style="`border-color: #AFAFC3!important;`">
                <v-text-field :readonly="!edit" v-model="data.results[3].result" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Welke stap(pen) heb je gezet op weg naar resultaat 4?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[3].step" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Periode</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[3].period" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Wat heb je daarmee bereikt?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[3].achievement" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
              </accordion>

              <accordion title=" <p> <b>Resultaat 5</b></p>" :style="`border-color: #AFAFC3!important;`">
                <v-text-field :readonly="!edit" v-model="data.results[4].result" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Welke stap(pen) heb je gezet op weg naar resultaat 5?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[4].step" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Periode</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[4].period" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
                <label for="">
                  <p>Wat heb je daarmee bereikt?</p>
                </label>
                <v-text-field :readonly="!edit" v-model="data.results[4].achievement" background-color="#D7D7E1"
                  class="theme-input" solo></v-text-field>
              </accordion>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined  @click="submit('concept')" v-if="edit"
                color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 3">
        <v-row>
          <v-col cols="12" md="8">
            <h2 class="mb-10">Meetpunten van de meetlat</h2>

            <v-row>
              <v-col cols="12" md="6">
                <div v-for="(subject, x) in data.measurement" :key="x">
                  <p :class="x > 0 ? 'mt-10 mb-10' : 'mt-0 mb-10'">
                    <label for="">{{ subject.label }}</label>
                  </p>
                  <v-row :key="i" v-for="(item, i) in subject.items">
                    <v-col cols="2" class="py-1">
                      <v-text-field solo dense width="50px" class="theme-input number-input" background-color="#ECF4EF"
                        type="number" hide-details min="0" max="100" v-model="data.measurement[x].items[i].value"
                        :readonly="!edit">
                      </v-text-field>
                    </v-col>
                    <v-col cols="10" class="my-auto py-1">
                      <p class="mb-0">
                        {{ item.label }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="1"></v-col>
            </v-row>
            <br>
            <label>Toelichting</label>
            <v-textarea background-color="#D7D7E1" class="theme-input" solo v-model="data.explenation"
              title="Toelichting"></v-textarea>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined  @click="submit('concept')" v-if="edit"
                color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>


      <div v-if="tab === 4">
        <v-row>
          <v-col cols="12" md="6">
            <h2 class="mb-10">Successen en zorgen</h2>

            <label>Wat zijn je successen en zorgen?</label>
            <v-textarea :readonly="!edit" background-color="#D7D7E1" class="theme-input" solo
              v-model="data.successes_and_worries" title="Wat zijn je successen en zorgen?"></v-textarea>

            <label>Leidt dat tot een interventie of koerswijziging? Zo ja, welke?</label>
            <v-textarea :readonly="!edit" background-color="#D7D7E1" class="theme-input" solo v-model="data.interventions"
              title="Leidt dat tot een interventie of koerswijziging? Zo ja, welke?"></v-textarea>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined  @click="submit('concept')" v-if="edit"
                color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>


      <div v-if="tab === 5">
        <v-row>
          <v-col cols="12" md="6">
            <h2 class="mb-10">Vooruitblik komende periode</h2>
          </v-col>
        </v-row>
        <v-row flat v-for="(outlook, key) in data.outlook" :key="key">
          <v-col cols="12" md="5">
            <label>Wat zijn de volgende stappen om te komen tot je Projectresultaten?</label>
            <v-textarea :readonly="!edit" background-color="#D7D7E1" class="theme-input" solo v-model="outlook.steps"
              title="Wat zijn de volgende stappen om te komen tot je Projectresultaten?"></v-textarea>
          </v-col>
          <v-col cols="12" md="2">
            <v-img src=""></v-img>
          </v-col>
          <v-col cols="12" md="5">
            <label>Wat gaan we daarmee bereiken?</label>
            <v-textarea :readonly="!edit" background-color="#D7D7E1" class="theme-input" solo
              v-model="outlook.achievement" title="Wat gaan we daarmee bereiken?"></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-btn class="ml-auto d-block" v-if="key > 0" fab small elevation="0" color="error"
              @click="removeoutlook(key)">
              <v-icon dark>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn class="mx-auto d-block" fab small elevation="0" color="primary" @click="addoutlook()">
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
        <p class="text-center mt-1">Velden toevoegen</p>

        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined  @click="submit('concept')" v-if="edit"
                color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="nextdialog = true" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>


      <!-- Download / submit dialog -->
      <v-dialog v-model="nextdialog" content-class="theme-popup">
        <div class="text-right">
          <v-btn @click="nextdialog = false" icon class="" elevation="0" color="themepurple">
            <v-icon>fas fa-times </v-icon>
          </v-btn>
        </div>
        <div v-if="(senddate = true)">
          <div class="text-center pb-5">
            <h3>Weet je zeker dat je wil inleveren?</h3>
          </div>
          <div class="text-center">
            <p>
                Wanneer je hem inlevert word de project Procesmanager/Clustermanager/Lijnmanager op de hoogste
                gesteld.
            </p>
          </div>
          <div class="text-center pt-5">
            <v-btn class="btn text-primary mr-3" color="#fff" @click="download()">Downloaden</v-btn>
            <v-btn class="btn" color="themepurple"  @click="submit('save')" dark>Inleveren</v-btn>
          </div>
        </div>
        <div v-else>
          <div class="text-center pb-5">
            <h3>Verstuurd</h3>
          </div>
          <div class="text-center">
            <p>Document is verstuurd naar organisator.</p>
          </div>
        </div>
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
import accordion from "@/components/accordion.vue";
import Feedbacksmiley from "@/components/feedbacksmiley.vue";
import api from "../../../services/api";
import config from '../../../config';
export default {
  components: { accordion, Feedbacksmiley },
  data() {
    return {
      sidebar: true,
      ready: true,
      nextdialog: false,
      senddata: false,
      submittype: "concept",
      edit: false,
      menuitems: [
        {
          title: "1. Projectkenmerken",
        },
        {
          title: "2. Hoe staat het ervoor met je project?",
        },
        {
          title: "3. Welke stappen heb je gezet op weg naar je resultaten?",
        },

        {
          title: "4. Meetpunten van de meetlat",
        },
        {
          title: "5. Successen en zorgen",
        },
        {
          title: "6. Vooruitblik komende periode",
        },
      ],
      tab: 0,
      project: {
        title: ""
      },
      data: {
        project: {
          purpose: "",
          rapportperiod: "",
          client: "",
          budgetowner: "",
        },
        feedback: "1",
        description: "",
        results: [
          {
            result: "",
            step: "",
            period: "",
            achievement: "",
          },
          {
            result: "",
            step: "",
            period: "",
            achievement: "",
          },
          {
            result: "",
            step: "",
            period: "",
            achievement: "",
          },
          {
            result: "",
            step: "",
            period: "",
            achievement: "",
          },
          {
            result: "",
            step: "",
            period: "",
            achievement: "",
          },
        ],
        measurement: [{
          label: "Perspectief van ouders en jeugdige versterken/verbeteren",
          items: [
            {
              label: "Het belang van de jeugdige staat voorop",
              value: 0
            },
            {
              label: "Jeugdigen en / of ouders worden actief betrokken",
              value: 0
            },
            {
              label: "Versterking van eigen kracht en verantwoordelijkheid van jeugdige en / of ouders",
              value: 0
            }
          ]
        }, {
          label: "Perspectief van de professionals",
          items: [
            {
              label: "Betere samenwerking: 1 gezin, 1 plan, 1 regisseur",
              value: 0
            },
            {
              label: "Minder regeldruk voor professionals",
              value: 0
            }
          ]
        },
        {
          label: "Kostenbeheersing",
          items: [
            {
              label: "Meer doelmatige hulp(efficiënt / effectief)",
              value: 0
            },
            {
              label: "Geen onnodige jeugdhulp als ook het netwerk iets kan betekenen (normaliseren en ontzorgen)",
              value: 0
            }
          ]
        },
        {
          label: "Verbeteren van kwaliteit van de jeugdhulp",
          items: [
            {
              label: "Jeugdhulp dichterbij(t)huis",
              value: 0
            },
            {
              label: "Juiste zorg op het juiste moment(ook voor de complexe doelgroep)",
              value: 0
            }
          ]
        }],
        explenation: "",
        successes_and_worries: "",
        interventions: "",
        outlook: [
          { steps: "", achievement: "" }
        ],

      },
    };
  },

  methods: {
    addoutlook() {
      this.data.outlook.push(
        {
          steps: "",
          achievement: "",
        }
      )
    },
    removeoutlook(index) {
      this.data.outlook.splice(index, 1);
    },
    getProjectDetails() {
      api.get(`${config.url.api}/v1/projects/${this.$route.params.id}`).then((res) => {
        this.project.title = res.data.title;
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$router.push("/pagina-niet-gevonden")
            break;
          case 404:
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error('Fout bij het ophalen van het project.')
            break;
        }
      })
    },
    submit(type) {
      var data = {
        project: this.$route.params.id,
        data: this.data,
        type: type
      };
      var patchslug = this.$route.params.subid === "nieuw" ? 0 : this.$route.params.subid;
      console.log(patchslug);
      api.patch(`${config.url.api}/v1/countingandtelling/${patchslug}`, data).then((res) => {
        if (res.data.success) {
          //redirect to the newly created recource.
          switch (type) {
            case "concept":
              this.$toast.success('Concept Tellen en vertellen formulier succesvol geupdate.');
              break;
            case "save":
              this.$toast.success('Tellen en vertellen succesvol ingeleverd.');
              this.nextdialog = false;
              break;
          }

          if (patchslug == 0) {
            this.$router.push("/project/" + this.$route.params.id + "/tellen-en-vertellen/" + res.data.slug)
          }

        } else {
          this.$toast.error('Er ging iets mis bij het opslaan van je tellen en vertellen formulier.')
        }
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$toast.error('Je hebt geen toegang tot dit tellen en vertellen formulier.')
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
            break;
        }
      })
    },
    getCountingAndTellingPlan() {
      api.get(`${config.url.api}/v1/countingandtelling/${this.$route.params.subid}`).then((res) => {
        this.data = res.data.data.data;
        this.edit = res.data.userrole == "PU";
        this.ready = true;
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$router.push("/pagina-niet-gevonden")
            break;
          case 404:
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error('Fout bij het ophalen van het tellen en vertellen formulier.')
            break;
        }
      })
    },
    next() {
      this.tab++;
      window.scrollTo(0, 0);
    },
    prev() {
      this.tab--;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    if (this.$route.params.subid != "nieuw") {
      this.getCountingAndTellingPlan();
    } else {
      this.edit = true;
    }
    this.getProjectDetails();
  },
  computed: {
    measurementTotal() {
      let total = 0;
      this.data.measurement.forEach(subject => {
        subject.items.forEach(item => {
          total = parseInt(total) + parseInt(item.value);
        });
      });
      return total;
    },
    measurementCompleteText() {
      if (this.measurementTotal > 100) {
        return `Let op: Er zijn ${(parseInt(this.measurementTotal - 100))} punten te veel verdeeld.`
      } else if (this.measurementTotal < 100) {
        return `Er zijn nog ${(100 - parseInt(this.measurementTotal))} punten te verdelen.`
      } else {
        return ""
      }
    },
    measurementCompleted() {
      return this.measurementTotal == 100;
    }

  },
};
</script>

<style>
body {
  transition: all ease-in-out 0.2s;
  background-color: #373669;
}

.sidebar-drawer {
  height: 100%;
  position: relative;
}

.sidebar-drawer .bottom-text {
  position: absolute !important;
  bottom: 20px !important;
  font-size: 16px;
  color: #3f4a5e80;
}

.theme--light.v-application {
  background: rgba(255, 255, 255, 0.96) !important;
  border-radius: 10px !important;
  margin-left: 5px;
  margin-top: 5px;
}

.v-list-item__title {
  font-size: 20px !important;
}

.v-list-item small {
  font-size: 14px;
}

.v-list-item.v-item--active {
  font-weight: bold;
}

.number-input {
  width: 70px;
}
</style>
